import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  MANGO_40,
  MOSS_100,
  OwnUpLineButton,
  OwnUpOverline,
  PropsWithTheme,
  SAGE_100
} from '@rategravity/own-up-component-library';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const customMobileBreakpoint = 870;

export const FaqItemWrapper = styled.div`
  // Something in MUI wants to add extra space below the title
  // in expanded FAQ items
  .MuiAccordionSummary-content > p {
    margin-bottom: 0;
  }
`;

export const FaqListWrapper = styled.div`
  // Spacing between faq items
  //   Note: a margin-top value is built into each :not(:first-child)
  //   accordion, so overriding it is the only reliable way to
  //   customize spacing
  ${FaqItemWrapper}:not(:first-child) {
    margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1.5)}px;
  }
`;

export const StyledFaqSection = styled.section<{ homePageColor?: boolean }>`
  background-color: ${(props) => (props.homePageColor ? SAGE_100 : MOSS_100)};

  // Spacing above and below content
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme, homePageColor }: PropsWithTheme<{ homePageColor?: boolean }>) =>
      homePageColor ? theme.spacing(6, 0, 0) : theme.spacing(6, 0)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    padding: ${({ theme, homePageColor }: PropsWithTheme<{ homePageColor?: boolean }>) =>
      homePageColor ? theme.spacing(8, 0, 0) : theme.spacing(8, 0)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme, homePageColor }: PropsWithTheme<{ homePageColor?: boolean }>) =>
      homePageColor ? theme.spacing(10, 0, 0) : theme.spacing(10, 0)};
  }
`;

const MoreFaqsButtonLink = styled(Link)`
  display: flex;
  justify-content: center;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('xs')} {
    width: 100%;
  }

  // Spacing above button
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
`;

export const MoreFAQsButton = ({ href }: { href: string }) => {
  const trackingInstance = useTrackingInstance();
  return (
    <MoreFaqsButtonLink
      to={href}
      tabIndex={-1}
      onClick={() =>
        trackingInstance.track(`ClickFaqCTA-home`, { 'page-location': 'home-more-faqs' })
      }
    >
      <OwnUpLineButton>More FAQs</OwnUpLineButton>
    </MoreFaqsButtonLink>
  );
};

export const FaqSectionListWrapper = styled.div`
  // Spacing above accordions inside a FaqSection
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
`;

export const FAQOverlineText = styled(OwnUpOverline)`
  background-color: ${MANGO_40};
  display: inline;
  border-radius: 4px;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0.5, 1, 0, 1)};
  h1 {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin: 0;
  }
`;
export const HomepageHeadline = styled.h2`
  font-size: 28px;
  line-height: 36px;
  font-weight: bold;
  margin: 0;
  text-align: center;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(705)} {
    max-width: 100%;
  }
`;
